<template>
  <div class="container">
    <div class="form">
      <div class="row">
        <div class="label">小区</div>
        <!-- <div class="item">{{ estateName }}</div> -->
        <van-field v-model="repairInfo.estateName" disabled/>
      </div>

      <div class="row">
        <div class="label">位置信息</div>
        <van-field v-model="repairInfo.buildingName" disabled/>
      </div>

      <div class="row">
        <div class="label">手机号</div>
        <van-field v-model="repairInfo.phone" disabled/>
      </div>

       <div class="row">
        <div class="label">维修员</div>
        <van-field v-model="repairInfo.repairUserName" disabled/>
      </div>

      <div class="row">
        <div class="label">上门时间</div>
        <van-field v-model="repairInfo.visitTime" disabled/>
      </div>
     
      <div class="row">
        <div class="label">报修类型</div>
        <van-field v-model="repairInfo.repairTypeName" disabled/>
      </div>

      <div class="row col" style="margin-bottom: 12px">
        <div class="label">图片</div>
        <img class="eImg" :src="item" alt="" v-for="(item, index) in imgList" :key="index" />
      </div>
      
      <div class="row start" style="margin-bottom: 12px">
        <div class="label">报修描述</div>
        <van-field rows="5" type="textarea" v-model="repairInfo.remark" disabled/>
      </div>

      <div class="row " style="margin-bottom: 12px">
        <div class="label">状态</div>
        <van-tag v-if="repairInfo.status == '0'" size="large" color="#f4f4f5" text-color="#909399">待处理</van-tag>
        <van-tag v-else-if="repairInfo.status == '1'" size="large" color="#f0f9eb" text-color="#67c23a">处理中</van-tag>
        <van-tag v-else-if="repairInfo.status == '2'" size="large" color="#fdf6ec" text-color="#e6a28b">处理完成</van-tag>
        <van-tag v-else-if="repairInfo.status == '3'" size="large" color="#dbedf9" text-color="#0080cb">已解决</van-tag>
        <van-tag v-else-if="repairInfo.status == '4'" size="large" color="#f4f4f5" text-color="#909399">未解决</van-tag>
      </div>

      <!-- 已解决工单有评分 -->
      <div class="row" v-if="repairInfo.status == '3'">
        <div class="label">评分</div>
        <van-rate v-model="repairInfo.score" readonly  color="#ffd21e" :size="26" :gutter="10" :count="5" />
      </div>

      <!-- 非待处理工单 且有维修图片 -->
      <div class="row col" style="margin-bottom: 12px" v-if="repairInfo.status != '0' && imgList2.length > 0">
        <div class="label">维修图片</div>
        <img class="eImg" :src="item" alt="" v-for="(item, index) in imgList2" :key="index" />
      </div>

      <!-- 非待处理、非已解决工单 -->
      <div class="row col" style="margin-bottom: 12px" v-if="repairInfo.status != '0' && repairInfo.status != '3' && imgList3.length > 0">
        <div class="label">未解决图片</div>
        <img class="eImg" :src="item" alt="" v-for="(item, index) in imgList3" :key="index" />
      </div>

      <!-- 未解决工单 -->
      <div class="row start" style="margin-bottom: 12px" v-if="repairInfo.status == 4">
        <div class="label">未解决原因</div>
        <van-field rows="5" type="textarea" v-model="repairInfo.repetition" disabled/>
      </div>
      
      <!-- 处理中工单 维修员进入 -->
      <div class="row start" v-if="repairInfo.status == '1' && isWorker == '1'">
        <div class="label must">处理图片</div>
        <div style="width: 100%">
          <van-uploader
            v-model="completeList"
            @oversize="oversize"
            :beforeDelete="beforeDelete"
            :max-size="5 * 1024 * 1024"
            :max-count="5"
            :afterRead="afterRead"
            :preview-size="100"
          />
        </div>
      </div>
    </div>

    

    <!-- 维修人员 操作 -->
    <template v-if="isWorker == '1'">
      <van-button v-if="repairInfo.status == 0 || repairInfo.status == 4" type="info" block @click="deal(1)">开始处理</van-button>
      <van-button v-else-if="repairInfo.status == 1" type="info" block @click="deal(2)">处理完成</van-button>
    </template>

    <!-- 用户 操作 -->
    <div class="btn-group" v-else-if="isWorker == '0' && repairInfo.status == 2">
      <van-button type="info" block @click="isSolve(1)">已解决</van-button>
      <van-button type="info" plain block @click="isSolve(2)">未解决</van-button>
    </div>

    <!-- 未解决弹窗 -->
    <van-dialog v-model="remarkBox" title="未解决描述" show-cancel-button @confirm="confirmRemark">
      <div class="dialog-in two">

        <div class="row top">
          <div class="label must">图片</div>
          <div style="width: 100%">
            <van-uploader
              v-model="repetitionList"
              @oversize="oversize"
              :beforeDelete="beforeDelete2"
              :max-size="5 * 1024 * 1024"
              :max-count="5"
              :afterRead="afterRead2"
              :preview-size="90"
            />
          </div>
        </div>

        <div class="row top">
          <div class="label must">描述</div>
          <div style="width: 100%">
            <van-field rows="4" v-model="remark" type="textarea"/>
          </div>
        </div>
    
      </div>
    </van-dialog>

    <!-- 已解决 评分弹窗 -->
    <van-dialog v-model="gradeBox" title="请对本次服务进行评分" show-cancel-button @confirm="confirmGrade">
      <div class="dialog-in star">
        <van-rate v-model="grade" color="#ffd21e" :size="30" :gutter="10" :count="5" />
      </div>
    </van-dialog>

  </div>
</template>
<script>
import { repairDetail, updateStatus, userUpdateStatus } from '@/api/repairApi'
import axios from '@/utils/axios';
import { Dialog } from 'vant';
export default {
  data() {
    return {
      remarkBox: false,
      gradeBox: false,
      estateName:'',//小区名称
      imgList: [], //用戶圖片
      imgList2: [], //维修员圖片
      imgList3: [], //未解决圖片
      completeList: [],
      repetitionList: [],
      repairPic: [],
      repetitionPic: [],

      isWorker: 0, // 是否是维修员
      repairInfo: {
      },
      id: '',
      remark: '',
      grade: 0
    }
  },
  created() {
    // this.getbuild()
    // this.getTypeList()
    // this.getWorkerList()
    this.getDetail(this.$route.query.id)
    this.id = this.$route.query.id
    this.isWorker = this.$route.query.isWorker
    // this.isWorker = 1
    this.estateName = this.$store.getters.userInfo.estateName
  },
  mounted() {},
  methods: {
    isSolve(type) {
      if(type==1) {
        this.grade = 0
        this.gradeBox = true
      } else {
        this.remark = ''
        this.repetitionList = []
        this.repetitionPic = []
        this.remarkBox = true
      }
    },
    deal(type) {
      if(type == 1) {
        Dialog.confirm({
          title: '是否开始处理报修',
          message: '',
        })
        .then(() => {
          // on confirm
          this.$store.commit('SET_IS_LOADING', true)
          updateStatus({
              repairUser: this.$store.getters.userInfo.id,
              id: this.id,
              status: 1
            }).then(res => {
              this.$store.commit('SET_IS_LOADING', false)
              if(res.code == 200) {
                  this.$toast({
                    type: 'success',
                    message: '操作成功',
                    duration: 1000,
                    onClose: () => {
                      this.$router.go(-1)
                    }
                  })
                  // this.getDetail(this.id)
              }
            })
          
        })
        .catch(() => {
          // on cancel
        });
      } else {
        if(this.repairPic.length == 0) {
          return this.$toast({
            type: 'warning',
            message: '请上传处理图片',
            duration: 1000,
          })
        }
        Dialog.confirm({
          title: '请确认报修已处理完成',
          message: '',
        }).then(() => {
          // on confirm
          this.$store.commit('SET_IS_LOADING', true)
          let arr = this.repairPic

          this.repairPic = ''
          this.repairPic = arr.join(',')
          this.$store.commit('SET_IS_LOADING', true)
          updateStatus({
            repairUser: this.$store.getters.userInfo.id,
            repairPic: this.repairPic,
            id: this.id,
            status: 2
          }).then(res => {
            this.$store.commit('SET_IS_LOADING', false)
            if(res.code == 200) {
                this.$toast({
                  type: 'success',
                  message: '操作成功',
                  duration: 1000,
                  onClose: () => {
                    this.$router.go(-1)
                  }
                })
                // this.getDetail(this.id)
            }
          })
        })
        .catch(() => {
          // on cancel
        });
        
      }
      
    },
    // 图片超出大小
    oversize() {
      this.$toast({
        type: 'warning',
        message: '图片不能超过5M~',
        duration: 500,
      })
    },
    // 删除处理图片
    beforeDelete(file, detail) {
      this.completeList.splice(detail.index, 1)
      this.repairPic.splice(detail.index, 1)
    },
    // 删除未解决图片
    beforeDelete2(file, detail) {
      this.repetitionList.splice(detail.index, 1)
      this.repetitionPic.splice(detail.index, 1)
    },
    // 未解决图片上传
    afterRead2(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'
      let url = file.file
      let formdata = new FormData()
      formdata.append('file', url)

      axios({
        baseURL: '/minio',
        url: '/minio/villageUpload?keepFileName=1&bucketName=village',
        method: 'post',
        data: formdata
      }).then(res => {
        if(res.code == 200) {
          file.url = res.data.url
          this.repetitionPic.push(res.data.url)
          file.status = 'done'
          file.message = ''
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    // 图片上传后，获取返回的参数，在提交时当做参数传递给后台，uploadImgList是图片上传后返回的图				片信息列表
    afterRead(file) {
      // 上传图
      file.status = 'uploading'
      file.message = '上传中'
      let url = file.file
      let formdata = new FormData()
      formdata.append('file', url)

      axios({
        baseURL: '/minio',
        url: '/minio/villageUpload?keepFileName=1&bucketName=village',
        method: 'post',
        data: formdata
      }).then(res => {
        if(res.code == 200) {
          file.url = res.data.url
          this.repairPic.push(res.data.url)
          file.status = 'done'
          file.message = ''
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
          file.status = 'failed'
          file.message = '上传失败'
        })
    },
    confirmGrade() {
      if(!this.grade) {
        return this.$toast({
            type: 'error',
            message: '请对本次服务进行评分',
            duration: 1000,
          })
      }
      this.$store.commit('SET_IS_LOADING', true)
      userUpdateStatus({
        id: this.id,
        status: 3,
        score: this.grade
      }).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        if(res.code == 200) {
          this.$toast({
            type: 'success',
            message: '操作成功',
            duration: 1000,
            onClose: () => {
              this.$router.go(-1)
            }
          })
          // this.getDetail(this.id)
        }
      })
    },
    confirmRemark() {
      if(this.repetitionPic.length == 0) {
        return this.$toast({
            type: 'error',
            message: '请上传未解决图片',
            duration: 1000,
          })
      }
      if(!this.remark) {
        return this.$toast({
            type: 'error',
            message: '描述不能为空',
            duration: 1000,
          })
      }
      this.$store.commit('SET_IS_LOADING', true)
      let arr = this.repetitionPic

      this.repetitionPic = ''
      this.repetitionPic = arr.join(',')
      userUpdateStatus({
          id: this.id,
          status: 4,
          repetition: this.remark,
          repetitionPic: this.repetitionPic,
        }).then(res => {
          this.$store.commit('SET_IS_LOADING', false)
          if(res.code == 200) {
            this.$toast({
              type: 'success',
              message: '操作成功',
              duration: 1000,
              onClose: () => {
                this.$router.go(-1)
              }
            })
            // this.getDetail(this.id)
          }
        })
    },
    getDetail(id) {
      this.$store.commit('SET_IS_LOADING', true)
      repairDetail({id: id}).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        this.repairInfo = res.data
        this.repairInfo.repairUserName = this.repairInfo.repairUserName?this.repairInfo.repairUserName:'暂未分配'
        this.imgList = res.data.ownerPic?res.data.ownerPic.split(','):[]
        // this.imgList.push('https://img.alicdn.com/imgextra/i2/O1CN01CtCb611ik9Gr7Jczj_!!6000000004450-54-tps-140-160.apng')
        this.imgList2 = res.data.repairPic?res.data.repairPic.split(','):[]
        this.imgList3 = res.data.repetitionPic?res.data.repetitionPic.split(','):[]
      })
    },



    
    
  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
.btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-in {
  width: 100%;
  padding: 0 20px;
}
.dialog-in.star {
  margin: 10px 0;
  text-align: center;
}
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
}
.eImg {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}
.row {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .van-field {
    padding: 2px 4px;
  }
  &.row2 {
    align-items: center;
  }
  &.col {
    flex-direction: column;
    align-items: start;
    .van-field {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      /* height: 100px; */
    }
    .label {
      margin-bottom: 10px;
    }
  }
  .label {
    width: 80px;
    flex-shrink: 0;
  }
  .label.must {
    position: relative;
  }
  .label.must::after {
    content: '*';
    color: #e10602;
    font-size: 18px;
    position: absolute;
    left: -8px;
    top: 1px;
  }
}
.dialog-in.two {
  padding-top: 10px;
  max-height: 340px;
  overflow: auto;
  .row {
    align-items: start;
    .label {
      font-size: 12px;
      width: 40px;
      flex-shrink: 0;
    }
  }
}
.form {
  min-height: calc(100vh - 24px - 46px - 56px);
}
.row.start {
  align-items: start;
}
::v-deep .row .van-uploader__upload {
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 5px;
}
.item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 5px;
}
.van-field {
  border: 1px solid #eeeeee;
  padding: 5px;
  border-radius: 5px;
}
</style>
